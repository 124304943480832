<template>
  <div>
    <b-card no-body>
      <template #header>
        <h4 class="mr-auto mb-0">
          {{ title }}
        </h4>
        <b-button-group
          v-if="features.extraButtons && features.extraButtons.length>0"
          size="sm"
        >
          <template v-for="(button, index) in features.extraButtons">
            <b-button
              :ref="button.buttonRef"
              :key="index"
              :variant="button.variant"
              @click="extraButton(button.buttonRef)"
            >
              {{ button.buttonLabel }}
            </b-button>
          </template>
          <b-button
            v-if="features.buttons.createButton"
            variant="primary"
            @click="create"
          >
            {{ features.buttons.createButtonLabel || `Create New` }}
          </b-button>
        </b-button-group>
      </template>

      <div
        v-if="features.tablePaymentProviderFilterButton && features.tablePaymentProviderFilterButton.length>0"
        class="border-top px-2 py-1"
      >
        <template v-for="(button, index) in features.tablePaymentProviderFilterButton">
          <b-button
            :ref="button.buttonRef"
            :key="index"
            class="mr-1"
            :class="{'btn-info': button.active}"
            @click="tablePaymentProviderFilterButtonClick(button.buttonRef, index)"
          >
            {{ button.buttonLabel }}
          </b-button>
        </template>
      </div>
      <div
        v-if="features.tableStatusFilterButton && features.tableStatusFilterButton.length>0"
        class="border-top px-2 py-1"
      >
        <template v-for="(button, index) in features.tableStatusFilterButton">
          <b-button
            :ref="button.buttonRef"
            :key="index"
            class="mr-1"
            :class="{'btn-info': button.active}"
            @click="tableStatusFilterButtonClick(button.buttonRef, index)"
          >
            {{ button.buttonLabel }}
          </b-button>
        </template>
      </div>
      <div
        v-if="features.txnTypeFilter && features.txnTypeFilter.length>0"
        class="border-top px-2 py-1"
      >
        <template v-for="(button, index) in features.txnTypeFilter">
          <b-button
            :ref="button.buttonRef"
            :key="index"
            class="mr-1"
            :class="{'btn-info': button.active}"
            @click="txnTypeFilterButtonClick(button.buttonRef, index)"
          >
            {{ button.buttonLabel }}
          </b-button>
        </template>
      </div>

      <b-row
        v-if="!!tableSettings"
        class="border-top"
      >

        <b-col
          cols="12"
          lg="4"
        >
          <b-form-group
            class="my-2"
            label-size="sm"
          >
            <template v-if="features.buttons.refresh">
              <b-button
                size="sm"
                class="mx-2"
                @click="refreshButton(dataGridEndpoint)"
              >
                <i class="fa fa-sync" /> Refresh
              </b-button>
            </template>
            <template v-if="features.perPageDropdown">
              <b-form-select
                v-model="selectedPerPage"
                :class="[{'ml-2': !features.buttons.refresh}]"
                class="w-25"
                :options="perPageOptions"
                size="sm"
                @change="initDataGrid(dataGridEndpoint)"
              />
            </template>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="8"
        >
          <div class="mt-2 mr-1">

            <template v-if="features.filter">
              <b-col>
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="text"
                    placeholder="Type to filter"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </template>
            <template v-if="features.search">
              <b-col cols="12">
                <b-row>
                  <b-col
                    cols="12"
                    lg="4"
                  >
                    <b-form-group>
                      <template v-if="features.datepicker">
                        <b-col>
                          <b-input-group size="sm">
                            <flat-pickr
                              v-model="rangeDate"
                              placeholder="Select Date"
                              class="form-control form-control-sm"
                              :config="{ mode: 'range',
                                         maxDate: new Date().fp_incr(1),
                                         dateFormat: 'd-m-Y',}"
                            />
                            <div class="input-group-append">
                              <button
                                class="btn btn-secondary"
                                type="button"
                                title="Clear"
                                @click="clearDateSearch"
                              >
                                Clear Date
                              </button>

                            </div>
                          </b-input-group>
                        </b-col>
                      </template>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    lg="4"
                  >
                    <b-form-select
                      v-model="searchField"
                      :options="searchOptionsDropdown"
                      size="sm"
                    />
                  </b-col>
                  <b-col>
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="searchTerm"
                        type="text"
                        placeholder="Type to Search"
                      />

                      <b-input-group-append>
                        <b-button
                          v-if="searchTerm"
                          @click="initDataGrid()"
                        >Clear</b-button>
                        <b-button
                          :disabled="!rangeDate && !searchTerm"
                          @click="searchGrid()"
                        >Search</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                </b-row>

              </b-col>

            </template>
          </div>
        </b-col>
      </b-row>

      <b-row
        class="border-top px-2 py-1"
      >
        <b-col>
          <template v-if="selectableTable">
            <b-button-group
              size="sm"
              class="mr-1"
            >
              <b-button
                size="sm"
                @click="selectAllRows"
              >
                <i class="fa fa-sync" /> Select All
              </b-button>
              <b-button
                variant="secondary"
                size="sm"
                @click="clearSelected"
              >
                <i class="fa fa-sync" /> Clear
              </b-button>
            </b-button-group>
          </template>
          <template v-if="selectableTable">
            <b-button-group
              size="sm"
              class="ml-1"
            >

              <b-button
                :disabled="selected <1"
                size="sm"
                variant="primary"
                @click="bulkAction('reconcile')"
              >
                Reconcile Selected
              </b-button>

            </b-button-group>
          </template>
        </b-col>

      </b-row>

      <b-table
        :ref="`selectableTable-${id}`"
        selected-variant="primary"
        :filter="filter"
        :no-local-sorting="true"
        striped
        bordered
        responsive
        :selectable="selectableTable"
        :select-mode="selectMode"
        show-empty
        hover
        :items="GridData"
        :busy="isBusy"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :fields="TableHeader"
        @row-selected="onRowSelected"
        @sort-changed="sortingChanged"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
            <br>
            <strong>Loading...</strong>
          </div>
        </template>
        <template
          v-for="x in TableHeader"
          v-slot:[getTableKey(x)]="row"
        >
          <lpdt-field
            :key="x.id"
            :row-data="row"
            :col-name="x.key"
            :col-type="x.type"
          />
        </template>

        <template
          v-if="features.showDTDetails"
          #cell(show_details)="row"
        >
          <template v-if="row.detailsShowing">
            <b-button
              variant="link"
            >
              <feather-icon
                size="16"
                icon="MinusCircleIcon"
                class="mr-50"
                @click="row.toggleDetails"
              />
            </b-button>
          </template>
          <template v-if="!row.detailsShowing">
            <b-button
              variant="link"
            >
              <feather-icon
                size="16"
                icon="PlusCircleIcon"
                class="mr-50"
                @click="row.toggleDetails"
              />
            </b-button>
          </template>
        </template>

        <!--Show Details Data-->
        <template #row-details="row">
          <b-card>
            <template
              v-for="x in conf.tableData().tableHeader"
            >
              <b-row
                v-if="x.showInDetails"
                :key="`sss${x.key}`"
                class="mb-1"
              >
                <b-col
                  class=" text-lg-right"
                  cols="12"
                  lg="3"
                >
                  <strong>{{ humanise(x.label) }}</strong>
                </b-col>
                <b-col
                  cols="12"
                  lg="9"
                >
                  <lpdt-field
                    :key="x.id"
                    :row-data="row"
                    :col-name="x.key"
                    :col-type="x.type"
                  />
                </b-col>
              </b-row>
            </template>
          </b-card>
        </template>

        <template #cell(admin_action)="row">
          <slot
            name="admin_action"
            :data="row.item"
          />
        </template>
        <template #cell(actions)="row">
          <slot
            name="action"
            :data="row.item"
          >

            <template v-if="actionCondition === 'FAILED'">
              <b-button
                size="sm"
                @click="failAdjustment(row.item.CustomerBill.id, actionCondition)"
              >
                Adjust ({{ row.item.CustomerBill.id }})
              </b-button>
            </template>
            <template v-if="actionCondition === 'NESTED_INVOICE_NO'">
              <b-button
                v-if="typeof row.item.CustomerBill.is_recon === 'boolean' && !row.item.CustomerBill.is_recon"
                :disabled="selected.length > 0"
                size="sm"
                @click="reconcile(row.item.CustomerBill.invoice_no, actionCondition)"
              >
                Reconcile
              </b-button>
            </template>
            <template v-if="TableActions.length > 0">
              <b-button-group size="sm">
                <template v-for="(btn,index) in TableActions">

                  <template v-if="btn.action === 'custom'">
                    <b-button
                      v-if="btn.disableType === 'boolean'"
                      :key="`button_actions-${index}`"
                      :disabled="row.item[btn.disableCondition.split(':')[0]] == JSON.parse(btn.disableCondition.split(':')[1])"
                      :variant="btn.variant"
                      @click="actionBtn(row.item[btn.param], btn.actionParam, row.item)"
                    >
                      <feather-icon
                        v-if="btn.btnIcon"
                        :size="btn.iconSize"
                        :icon="btn.btnIcon"
                      />
                      <template v-if="!btn.btnOnly">
                        {{ btn.btnLabel }}
                      </template>
                    </b-button>
                    <b-button
                      v-else
                      :key="`button_actions-${index}`"
                      :disabled="row.item[btn.disableCondition.split(':')[0]] == btn.disableCondition.split(':')[1]"
                      :variant="btn.variant"
                      @click="actionBtn(row.item[btn.param], btn.actionParam, row.item)"
                    >
                      <feather-icon
                        v-if="btn.btnIcon"
                        :size="btn.iconSize"
                        :icon="btn.btnIcon"
                      />
                      <template v-if="!btn.btnOnly">
                        {{ btn.btnLabel }}
                      </template>
                    </b-button>
                  </template>
                  <template v-if="btn.action === 'view'">
                    <b-button
                      :key="`button_actions-${index}`"
                      :variant="btn.variant"
                      @click="viewRow(row.item[btn.param])"
                    >
                      <feather-icon
                        v-if="btn.btnIcon"
                        :size="btn.iconSize"
                        :icon="btn.btnIcon"
                      />
                      <template v-if="!btn.btnOnly">
                        {{ btn.btnLabel }}
                      </template>
                    </b-button>
                  </template>
                  <template v-if="btn.action === 'edit'">
                    <b-button
                      :key="`button_actions-${index}`"
                      :variant="btn.variant"
                      @click="editRow(row.item[btn.param])"
                    >
                      <feather-icon
                        v-if="btn.btnIcon"
                        :size="btn.iconSize"
                        :icon="btn.btnIcon"
                      />
                      <template v-if="!btn.btnOnly">
                        {{ btn.btnLabel }}
                      </template>
                    </b-button>
                  </template>
                  <template v-if="btn.action === 'remove'">
                    <b-button
                      :key="`button_actions-${index}`"
                      :variant="btn.variant"
                      @click="deleteRow(row.item[btn.param])"
                    >
                      <feather-icon
                        v-if="btn.btnIcon"
                        :size="btn.iconSize"
                        :icon="btn.btnIcon"
                      />
                      <template v-if="!btn.btnOnly">
                        {{ btn.btnLabel }}
                      </template>
                    </b-button>
                  </template>

                </template>
              </b-button-group>
            </template>
          </slot>

        </template>
      </b-table>

      <lpdt-pagination
        :grid-settings="GridSettings"
        :selected-per-page="selectedPerPage"
        :current-page="currentPage"
        :search-field="searchField"
        :is-busy="isBusy"
        :table-settings="tableSettings"
        @paginationClicked="pagination($event)"
      />
    </b-card>

  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import humaniseMixin from '@/common/humanise.mixin'
import LpdtField from '@/leanpay-components/LPDTField.vue'
import LpdtPagination from '@/leanpay-components/LPDTPagination.vue'

export default {
  components: { LpdtField, LpdtPagination, flatPickr },
  mixins: [humaniseMixin],
  props: {
    actionCondition: {
      required: false,
      type: String,
    },
    id: {
      required: false,
      type: String,
      default: '',
    },
    storeModule: {
      required: true,
      type: String,
    },
    selectableTable: {
      required: false,
      type: Boolean,
      default: false,
    },
    storeDataEndpoint: {
      required: true,
      type: String,
    },
    loadSystemConstant: {
      type: Boolean,
      default: false,
    },
    storeGetterEndpoint: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    currentSelectedStatus: {
      type: Object,
    },
    queryParam: {
      required: false,
      type: String,
    },
    rawDataKey: {
      required: false,
      type: String,
      default: 'getRawData',
    },
    defaultSortField: {
      required: false,
      type: String,
      default: 'created_at',
    },
    extraParameter: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
    defaultSearchField: {
      required: true,
      type: String,
    },
    rawData: {
      required: false,
      type: Object,
    },
    paginationStateKey: {
      required: false,
      type: String,
    },
    searchOptionsDropdown: {
      required: false,
      type: Array,
    },
    conf: {
      required: false,
      type: Object,
    },
    pageConf: {
      required: false,
      type: Object,
    },
    features: {
      required: false,
      type: Object,
      default() {
        return {
          datatableFeatures: {
            buttons: {
              createButton: false,
              refresh: false,
            },
            perPageDropdown: true,
            filter: true,
            pagination: true,
            search: false,
          },
        }
      },
    },
  },
  data() {
    return {
      rangeDate: null,
      cardTitle: 'title',
      dataGridEndpoint: [],
      module: 'development',

      selectMode: 'multi',
      selected: [],

      // DataGridSetting
      filter: '',
      searchTerm: '',
      currentPage: 1,
      isBusy: true,
      selectedPerPage: 50,
      sortDesc: true,

      record_status: 1, // active only
      searchField: '',
      sortBy: 'desc',
      sort: '',
    }
  },
  computed: {
    processedDateRange() {
      let dateRange = this.rangeDate
      if (dateRange) {
        dateRange = dateRange.split(' to ')
        if (dateRange.length > 1) {
          return {
            startDate: dateRange[0],
            endDate: dateRange[1],
          }
        }
        return {
          startDate: dateRange[0],
          endDate: dateRange[0],
        }
      }
      return this.defaultDateRange
    },
    formSchema() {
      const list = this.$store.getters[`${this.storeModule}/${this.storeGetterEndpoint}`]
      // return Object.getOwnPropertyNames(list[0])
      const x = []
      for (const [key, value] of Object.entries(list[0])) {
        x.push({ name: key, type: typeof value })
      }
      return x
    },
    tableConfig() {
      if (this.conf) {
        return this.conf.tableData()
      }
      return {}
    },
    tableSettings() {
      const list = this.$store.getters[`${this.storeModule}/${this.rawDataKey}`](this.paginationStateKey)
      // const { list } = dummyRes.data
      const perPage = this.selectedPerPage
      let listLength
      if (list !== null) {
        listLength = this.GridSettings.recordsTotal
      } else {
        listLength = 100
      }
      return {
        rows: listLength,
        perPage,
        refresh: true,
        filter: false,
        pagination: true,
        search: true,
      }
    },
    TableHeader() {
      let x = this.tableConfig.tableHeader
      if (this.features.tableActionField && x.length > 0) {
        x = [
          ...x,
          {
            key: 'actions',
            label: 'Action',
            class: 'text-right',
            visible: true,
          },
        ]
      }
      if (this.actionCondition && this.extraParameter.invoice_status[0] === 'FAILED' && this.actionCondition === 'FAILED') {
        x = [
          ...x,
          {
            key: 'actions',
            label: 'Action',
            class: 'text-right',
            visible: true,
          },
        ]
      }

      if (this.selectableTable) {
        x.unshift({
          key: 'selected',
          label: '',
          sortable: false,
          visible: true,
          type: 'visualcheckbox',
        })
      }

      return x.filter(y => y.visible)
    },
    TableActions() {
      const x = this.tableConfig.tableAction
      return x.filter(y => y.enable)
    },
    sortObj() {
      const obj = {
        parameter_name: this.sort,
        sort_type: this.sortDesc ? 'desc' : 'asc',
      }
      return obj
    },
    GridData() {
      const list = this.$store.getters[`${this.storeModule}/${this.storeGetterEndpoint}`]
      if (list !== null) {
        return list
      }
      return []
    },
    GridSettings() {
      const list = this.$store.getters[`${this.storeModule}/${this.rawDataKey}`](this.paginationStateKey)
      if (list !== null) {
        return {
          draw: list.draw,
          recordsTotal: list.record_total,
          next_page: list.next_page,
          next_page_length: list.next_page_length,
          next_page_start: list.next_page_start,
          previous_page_length: list.previous_page_length,
          previous_page_start: list.previous_page_start,
          previouse_page: list.previouse_page,
          recordsFiltered: list.record_filtered,
        }
      }
      return []
    },
  },
  async mounted() {
    this.isBusy = false

    this.searchField = this.defaultSearchField
    this.sort = this.defaultSortField

    if (this.loadSystemConstant) {
      await this.$store.dispatch('systemConstants/getSystemConstants')
    }

    let payload = {
      start_date: this.processedDateRange.startDate,
      end_date: this.processedDateRange.endDate,
      limit: this.tableSettings.perPage,
      skip: 0,
      search: {
        search_enable: false,
        search_key: '',
        search_column: '',
        search_replace_word_enable: true,
        search_word_replace: ' ',
        search_word_replace_to: '_',
      },
      sort: this.sortObj,
      ...this.extraParameter,
    }

    if (this.queryParam && typeof this.queryParam === 'string') {
      const y = this.queryParam
      const obj = { [y]: this.$route.params[this.toCamelCase(this.queryParam)] }

      payload = { ...obj, ...payload }
    }

    if (this.currentSelectedStatus) {
      payload = {
        ...payload,
        search: {
          search_enable: true,
          search_key: [this.currentSelectedStatus.q],
          search_column: [this.currentSelectedStatus.field],
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
      }
    }
    await this.$store.dispatch(`${this.storeModule}/${this.storeDataEndpoint}`, payload)
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs[`selectableTable-${this.id}`].selectAllRows()
    },
    clearSelected() {
      this.$refs[`selectableTable-${this.id}`].clearSelected()
    },
    bulkAction(type) {
      if (type === 'reconcile') {
        this.$emit('bulk-reconcile-clicked', this.selected)
      }
    },
    failAdjustment(id, actionCondition) {
      if (actionCondition === 'FAILED') {
        this.$emit('adjustment-clicked', id)
      }
    },
    reconcile(invoice_no, actionCondition) {
      if (actionCondition === 'NESTED_INVOICE_NO') {
        this.$emit('reconcile-clicked', invoice_no)
      }
    },
    clearDateSearch() {
      this.rangeDate = null
      this.initDataGrid()
    },
    getTableKey(x) {
      return `cell(${x.key})`
    },
    displaySystemConstantsLabel(key, id = null) {
      const recordStatusList = this.$store.getters['systemConstants/getSystemConstantsByKey'](key)
      if (Array.isArray(recordStatusList) && recordStatusList.length && id !== null) {
        const x = recordStatusList.filter(el => el.value === parseInt(id, 10))
        // return x
        // return this.humanise(
        //   x[0].name,
        // )
      }
      return 'NA'
    },
    editRow(itemId) {
      if (this.debugMode && this.$store.getters['auth/getDebugToggle']) {
        console.log('edit', itemId)
      }
      this.$emit('editBtn-clicked', itemId)
      const x = this.TableActions.filter(el => el.action === 'edit')
      if (x[0].pageRoute) {
        this.$router.push({
          name: x[0].pageRoute,
          params: { id: itemId, mode: 'edit' },
        })
      } else {
        this.$emit('editBtn-clicked', itemId)
      }
    },
    deleteRow(itemId) {
      if (this.debugMode && this.$store.getters['auth/getDebugToggle']) {
        console.log('DT deleteRow', itemId)
      }
      this.$emit('deleteBtn-clicked', itemId)
    },
    actionBtn(itemId, actionName, row) {
      if (this.debugMode && this.$store.getters['auth/getDebugToggle']) {
        console.log('DT actionBtn', itemId)
      }

      this.$emit('actionBtn-clicked', { rowData: row, actionName, value: itemId })
    },
    viewRow(itemId) {
      if (this.debugMode && this.$store.getters['auth/getDebugToggle']) {
        console.log('DT viewRow', itemId)
      }

      const x = this.TableActions.filter(el => el.action === 'view')
      if (x[0].pageRoute) {
        this.$router.push({
          name: x[0].pageRoute,
          params: { id: itemId, mode: 'view' },
        })
      } else {
        this.$emit('viewBtn-clicked', itemId)
      }
    },
    extraButton(actionName) {
      if (this.debugMode && this.$store.getters['auth/getDebugToggle']) {
        console.log('DT extraBtn', actionName)
      }

      this.$emit('extraBtn-clicked', actionName)
    },
    tableStatusFilterButtonClick(actionName, index) {
      console.log(this.features)
      this.features.tableStatusFilterButton.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.active = false
      })
      this.features.tableStatusFilterButton[index].active = true
      this.$emit('status-filter-btn-clicked', actionName)
      console.log(index)
    },
    txnTypeFilterButtonClick(actionName, index) {
      console.log(this.features)
      this.features.txnTypeFilter.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.active = false
      })
      this.features.txnTypeFilter[index].active = true
      this.$emit('txn-type-filter-btn-clicked', actionName)
      console.log(index)
    },
    tablePaymentProviderFilterButtonClick(actionName, index) {
      console.log(index, actionName)
      this.features.tablePaymentProviderFilterButton.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.active = false
      })
      this.features.tablePaymentProviderFilterButton[index].active = true
      this.$emit('payment-provider-btn-clicked', actionName)
    },
    create() {
      if (this.debugMode && this.$store.getters['auth/getDebugToggle']) {
        console.log('create')
      }
      this.$router.push({
        name: this.features.buttons.createButtonRoute,
      })
    },
    pagination(e) {
      let payload = {
        start_date: this.processedDateRange.startDate,
        end_date: this.processedDateRange.endDate,
        limit: this.tableSettings.perPage,
        skip: (e - 1) * this.tableSettings.perPage,
        record_status: 1,
        search: {
          search_enable: true,
          search_key: this.searchTerm,
          search_column: this.searchField,
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: this.sortObj,
        ...this.extraParameter,
      }
      if (this.queryParam && typeof this.queryParam === 'string') {
        const y = this.queryParam
        const obj = { [y]: this.$route.params[this.toCamelCase(this.queryParam)] }

        payload = { ...obj, ...payload }
      }

      this.isBusy = true
      this.$store.dispatch(`${this.storeModule}/${this.storeDataEndpoint}`, payload).then(() => {
        this.isBusy = false
        this.currentPage = e
      })
    },
    sortingChanged(ctx) {
      this.sortDesc = !this.sortDesc
      this.sortBy = ctx.sortBy
      // alert(ctx.sortBy)

      let payload = {
        start_date: this.processedDateRange.startDate,
        end_date: this.processedDateRange.endDate,
        limit: this.tableSettings.perPage,
        skip: 0,
        record_status: 1,
        search: {
          search_enable: false,
          search_key: this.searchTerm,
          search_column: this.searchField,
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: {
          parameter_name: ctx.sortBy,
          sort_type: this.sortDesc ? 'desc' : 'asc',
        },
        ...this.extraParameter,
      }

      if (this.searchField !== '') {
        payload = {
          start_date: this.processedDateRange.startDate,
          end_date: this.processedDateRange.endDate,
          limit: this.tableSettings.perPage,
          skip: 0,
          record_status: 1,
          search: {
            search_enable: false,
            search_key: '',
            search_column: '',
            search_replace_word_enable: true,
            search_word_replace: ' ',
            search_word_replace_to: '_',
          },
          sort: {
            parameter_name: ctx.sortBy,
            sort_type: this.sortDesc ? 'desc' : 'asc',
          },
          ...this.extraParameter,
        }
      }

      if (this.queryParam && typeof this.queryParam === 'string') {
        const y = this.queryParam
        const obj = { [y]: this.$route.params[this.toCamelCase(this.queryParam)] }

        payload = { ...obj, ...payload }
      }

      this.isBusy = true
      this.$store.dispatch(`${this.storeModule}/${this.storeDataEndpoint}`, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false
      })
    },
    searchGrid() {
      let payload = {
        start_date: this.processedDateRange.startDate,
        end_date: this.processedDateRange.endDate,
        limit: this.tableSettings.perPage,
        skip: 0,
        search: {
          search_enable: true,
          search_key: this.searchTerm,
          search_column: this.searchField,
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: this.sortObj,
        ...this.extraParameter,
      }
      if (this.queryParam && typeof this.queryParam === 'string') {
        const y = this.queryParam
        const obj = { [y]: this.$route.params[this.toCamelCase(this.queryParam)] }

        payload = { ...obj, ...payload }
      }

      if (this.currentSelectedStatus) {
        payload = {
          ...payload,
          search: {
            search_enable: true,
            search_key: [this.searchTerm],
            search_column: [this.searchField, this.currentSelectedStatus.field],
            search_replace_word_enable: true,
            search_word_replace: ' ',
            search_word_replace_to: '_',
          },
        }
      }

      this.isBusy = true
      this.$store.dispatch(`${this.storeModule}/${this.storeDataEndpoint}`, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false
      })
    },
    refreshButton(endpoint = null) {
      this.$emit('refresh-button-clicked', true)
      if (Array.isArray(endpoint) && endpoint.length > 0) {
        this.initDataGrid(endpoint)
      } else {
        this.initDataGrid()
      }
    },
    initDataGrid() {
      console.log('---->', this.extraParameter)
      let payload = {
        start_date: this.processedDateRange.startDate,
        end_date: this.processedDateRange.endDate,
        limit: this.tableSettings.perPage,
        skip: 0,
        search: {
          search_enable: false,
          search_key: '',
          search_column: '',
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: this.sortObj,
        ...this.extraParameter,
      }

      console.log('payload', payload)

      if (this.queryParam && typeof this.queryParam === 'string') {
        const y = this.queryParam
        const obj = { [y]: this.$route.params[this.toCamelCase(this.queryParam)] }

        payload = { ...obj, ...payload }
      }
      if (this.currentSelectedStatus) {
        payload = {
          ...payload,
          search: {
            search_enable: true,
            search_key: [this.currentSelectedStatus.q],
            search_column: [this.currentSelectedStatus.field],
            search_replace_word_enable: true,
            search_word_replace: ' ',
            search_word_replace_to: '_',
          },
        }
      }

      this.isBusy = true
      this.$store.dispatch(`${this.storeModule}/${this.storeDataEndpoint}`, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false

        this.searchTerm = ''
      })
    },
  },
}
</script>

<style>
.show-details.col-width { width: 20px; padding: 0}
</style>
