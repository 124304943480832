var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.paginationActive)?_c('div',[_c('div',{staticClass:"row"},[(_vm.GridSettings.draw < 1)?_c('div',{staticClass:"col"},[_c('label',{staticClass:"p-2"},[_vm._v("Showing nothing (No data)")])]):_vm._e(),(!_vm.isBusy && _vm.GridSettings.draw > 0)?_c('div',{staticClass:"col"},[_c('label',{staticClass:"p-2"},[_vm._v(" Showing "+_vm._s((_vm.currentPage - 1) * _vm.selectedPerPage + 1)+" to "),(
            Math.ceil(_vm.tableSettings.rows / _vm.selectedPerPage) ===
              _vm.currentPage
          )?[_vm._v(" "+_vm._s(_vm.GridSettings.recordsFiltered)+" ")]:_vm._e(),(_vm.currentPage === 1 && _vm.GridSettings.draw <= _vm.selectedPerPage && Math.ceil(_vm.tableSettings.rows / _vm.selectedPerPage) !==
            _vm.currentPage)?[_vm._v(" "+_vm._s(_vm.GridSettings.draw)+" ")]:_vm._e(),(_vm.currentPage !== 1 && Math.ceil(_vm.tableSettings.rows / _vm.selectedPerPage) !==
            _vm.currentPage)?[_vm._v(" "+_vm._s(_vm.selectedPerPage * _vm.currentPage)+" ")]:_vm._e(),_vm._v(" of "),(
            _vm.GridSettings.recordsTotal !== _vm.GridSettings.recordsFiltered
          )?[_vm._v(" "+_vm._s(_vm.GridSettings.recordsFiltered)+" "),(_vm.GridSettings.recordsFiltered > 1)?[_vm._v("entries")]:[_vm._v("entry")]]:[_vm._v(" "+_vm._s(_vm.GridSettings.recordsTotal)+" "),(_vm.GridSettings.recordsTotal > 1)?[_vm._v("entries")]:[_vm._v("entry")]]],2),(_vm.GridSettings.recordsTotal !== _vm.GridSettings.recordsFiltered)?[_c('label',[_vm._v("( filtered from "+_vm._s(_vm.GridSettings.recordsTotal)+" total "),(_vm.GridSettings.recordsTotal > 1)?[_vm._v("entries")]:[_vm._v("entry")],_vm._v(" )")],2)]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"col"},[(_vm.searchField === '')?_c('b-pagination',{staticClass:"pl-0 justify-content-end mt-2 mr-1",attrs:{"disabled":_vm.GridSettings.draw < 1,"first-number":"","last-number":"","total-rows":_vm.tableSettings.rows,"per-page":_vm.tableSettings.perPage},on:{"change":function($event){return _vm.pagination($event)}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e(),(_vm.searchField !== '')?_c('b-pagination',{staticClass:"pl-0 justify-content-end mt-2 mr-1",attrs:{"disabled":_vm.GridSettings.draw < 1,"first-number":"","last-number":"","total-rows":_vm.GridSettings.recordsFiltered,"per-page":_vm.tableSettings.perPage},on:{"change":function($event){return _vm.pagination($event)}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)]),(_vm.debugMode && _vm.$store.getters['auth/getDebugToggle'])?_c('pre',[_vm._v(_vm._s(_vm.GridSettings))]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }