<template>
  <div v-if="paginationActive">
    <div class="row">
      <div
        v-if="GridSettings.draw < 1"
        class="col"
      ><label class="p-2">Showing nothing (No data)</label></div>
      <div
        v-if="!isBusy && GridSettings.draw > 0"
        class="col"
      >
        <label class="p-2">
          Showing {{ (currentPage - 1) * selectedPerPage + 1 }} to
          <!-- check if last page-->
          <template
            v-if="
              Math.ceil(tableSettings.rows / selectedPerPage) ===
                currentPage
            "
          >
            {{ GridSettings.recordsFiltered }}
          </template>
          <template
            v-if="currentPage === 1 && GridSettings.draw <= selectedPerPage && Math.ceil(tableSettings.rows / selectedPerPage) !==
              currentPage"
          >
            {{ GridSettings.draw }}
          </template>
          <template
            v-if="currentPage !== 1 && Math.ceil(tableSettings.rows / selectedPerPage) !==
              currentPage"
          >
            {{ selectedPerPage * currentPage }}
          </template>
          of
          <template
            v-if="
              GridSettings.recordsTotal !== GridSettings.recordsFiltered
            "
          >
            {{ GridSettings.recordsFiltered }}
            <template v-if="GridSettings.recordsFiltered > 1">entries</template>
            <template v-else>entry</template>
          </template>
          <template v-else>
            {{ GridSettings.recordsTotal }}

            <template v-if="GridSettings.recordsTotal > 1">entries</template>
            <template v-else>entry</template>
          </template>
        </label>
        <template
          v-if="GridSettings.recordsTotal !== GridSettings.recordsFiltered"
        >
          <label>( filtered from {{ GridSettings.recordsTotal }} total
            <template v-if="GridSettings.recordsTotal > 1">entries</template>
            <template v-else>entry</template>
            )</label>
        </template>
      </div>
      <div class="col">
        <b-pagination
          v-if="searchField === ''"
          v-model="currentPage"
          :disabled="GridSettings.draw < 1"
          class="pl-0 justify-content-end mt-2 mr-1"
          first-number
          last-number
          :total-rows="tableSettings.rows"
          :per-page="tableSettings.perPage"
          @change="pagination($event)"
        />

        <b-pagination
          v-if="searchField !== ''"
          v-model="currentPage"
          :disabled="GridSettings.draw < 1"
          class="pl-0 justify-content-end mt-2 mr-1"
          first-number
          last-number
          :total-rows="GridSettings.recordsFiltered"
          :per-page="tableSettings.perPage"
          @change="pagination($event)"
        />
      </div>
    </div>

    <pre v-if="debugMode && $store.getters['auth/getDebugToggle']">{{ GridSettings }}</pre>
  </div>

</template>

<script>

export default {
  name: 'LeanpayDatatablePagination',
  components: {
  },
  props: {
    paginationActive: {
      type: Boolean,
      default: true,
    },
    selectedPerPage: {
      type: Number,
      default: 10,
    },
    searchField: {
      type: String,
      default: '',
    },
    currentPage: {
      type: Number,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
      default: true,
    },
    GridSettings: {
      type: Object,
      required: true,

    },
    tableSettings: {
      type: Object,
      required: true,
      default() {
        return {
          rows: 100,
          perPage: 50,
          refresh: true,
          filter: false,
          pagination: true,
          search: true,
        }
      },
    },
  },
  data: () => ({
  }),
  methods: {
    pagination(e) {
      this.$emit('paginationClicked', e)
    },
  },
}
</script>
