<template>
  <div>
    <template v-if="colType === 'monetary'">
      <vue-numeric
        v-if="colData"
        read-only
        :precision="2"
        :currency="myCurrency"
        separator=","
        :value="colData"
      />
      <vue-numeric
        v-else
        read-only
        :precision="2"
        :currency="myCurrency"
        separator=","
        :value="0"
      />
    </template>
    <template v-if="colType === 'string'">
      {{ colData }}

    </template>
    <template v-if="colType === 'payout_check'">
      {{ colData }}

    </template>

    <template v-if="colType === 'payment_url'">
      <!--      {{ colData }}-->
      <b-link
        target="_blank"
        :href="`${collectionBaseUrl}${rowData.item.uuid}`"
      ><feather-icon
        icon="ExternalLinkIcon"
      /></b-link>
    </template>
    <template v-if="colType === 'settlementUuid'">
      {{ colData }}
      <template v-if="colData">
        <b-button
          size="sm"
          variant="link"
          @click="traverse(colData)"
        >
          View Details
        </b-button>
      </template>
    </template>
    <template v-if="colType === 'invoiceUrl'">
      <!--      {{ colData }}-->
      <b-link
        target="_blank"
        :href="`${invoiceBaseUrl}${rowData.item.invoice_no}`"
      ><feather-icon
        icon="ExternalLinkIcon"
      /></b-link>
    </template>
    <template v-if="colType === 'collection_method'">
      <template v-if="colData === 1">
        Payment Form
      </template>
      <template v-if="colData === 2">
        Bill Form
      </template>
    </template>
    <template v-if="colType === 'underscore_string'">
      {{ humanise(colData) }}
    </template>
    <template v-if="colType === 'costCentreTxnType'">
      <template v-if="colData === 1">
        Normal
      </template>
      <template v-if="colData === 2">
        Prefund
      </template>
      <template v-if="colData === 3">
        Transaction
      </template>
      <template v-if="colData === 4">
        Bill Payment
      </template>
    </template>
    <template v-if="colType === 'email'">
      {{ colData }}
    </template>
    <template v-if="colType === 'bankname'">
      {{ getBankName(colData) }}
    </template>
    <template v-if="colType === 'code'">
      <code>{{ colData }}</code>
    </template>
    <template v-if="colType === 'date'">
      <template v-if="colData">
        {{ transformDate(colData, false, 'FF' ) }}
      </template>
      <template v-else>
        -
      </template>
    </template>
    <template v-if="colType === 'flag'">
      {{ colData }}
    </template>
    <template v-if="colType === 'preWrap'">
      <pre v-if="rowData.item.transaction_status === 'SUCCESS'">SUCCESS - Callback not displayed</pre>
      <pre v-else>{{ parseJsonData(colData) }}</pre>

    </template>
    <template v-if="colType === 'bankResponse_wrap'">
      <pre>{{ parseJsonData(colData) }}</pre>

    </template>
    <template v-if="colType === 'dataJSON'">
      <pre>{{ parseJsonData(colData) }}</pre>
    </template>

    <template v-if="colType === 'extractedClientDataJSON'">
      <pre>{{ parseExtractedJsonData(rowData.item, colName) }}</pre>
    </template>
    <template v-if="colType === 'txnStatusString'">
      <b-badge
        size="sm"
        :class="[{'badge-success': rowData.item.transaction_status === 'SUCCESS'},{'badge-danger': rowData.item.transaction_status === 'FAILED'}, {'badge-warning': rowData.item.transaction_status === 'PENDING'}]"
      >
        {{ rowData.item.transaction_status }}
      </b-badge>
    </template>

    <template v-if="colType === 'invoiceStatus'">
      <b-badge
        size="sm"
        :class="[{'badge-success': rowData.item.invoice_status_id === 2},{'badge-danger': rowData.item.invoice_status_id === 3}, {'badge-warning': rowData.item.invoice_status_id === 1}]"
      >
        {{ rowData.item.invoice_status }}
      </b-badge>
    </template>
    <template v-if="colType === 'settlement_status'">
      <b-badge
        size="sm"
        :class="[{'badge-success': rowData.item.status === 'COMPLETED'},{'badge-danger': rowData.item.status === 'FAIL_SETTLEMENT'}, {'badge-warning': rowData.item.status === 'PENDING_SETTLEMENT'}, {'badge-warning': rowData.item.status === 'PENDING'}]"
      >
        {{ humanise(rowData.item.status ) }}
      </b-badge>
    </template>
    <template v-if="colType === 'recordStatus'">
      <b-badge
        size="sm"
        :class="[{'badge-success': rowData.item.record_status === 1}, {'badge-warning': rowData.item.record_status === 2}]"
      >
        {{ displaySystemConstantsLabel('record_status', rowData.item.record_status) }}
      </b-badge>
    </template>
    <template v-if="colType === 'number'">
      {{ colData }}
    </template>
    <template v-if="colType === 'paymentRateAdditionalFee'">
      <template v-if="rowData.item.add_additional_fee">
        <vue-numeric
          read-only
          :precision="2"
          :currency="myCurrency"
          separator=","
          :value="rowData.item.additional_fee_amount"
        />
      </template>
      <template v-else>
        -
      </template>
    </template>
    <template v-if="colType === 'paymentRatePercentage'">
      <vue-numeric
        read-only
        :precision="2"
        separator=","
        :value="colData"
      /> %
    </template>

    <template v-if="colType === 'paymentRateChannel'">
      <b-badge
        size="sm"
        :class="[{'badge-success': colData === 'Threshold'}, {'badge-info': colData === 'MAM Charge'}]"
      >
        {{ colData }}
      </b-badge>
    </template>

    <template v-if="colType === 'decimal'">
      {{ colData }}
    </template>
    <template v-if="colType === 'boolFlag'">
      <template v-if="colData">
        <feather-icon
          size="16"
          class="text-success"
          icon="CheckIcon"
        />
      </template>
      <template v-else-if="!colData">
        <feather-icon
          size="16"
          class="text-danger"
          icon="XIcon"
        />
      </template>
      <template v-else>
        <feather-icon
          size="16"
          icon="XIcon"
        />
      </template>
    </template>
    <template v-if="colType === 'nestedKey'">
      <!--      {{ rowData.item }}-->
      {{ processNestedData(rowData.item, colName) }}
    </template>

    <template v-if="colType === 'nestedKeyStatus'">

      <b-badge
        v-if="processNestedData(rowData.item, colName)"
        size="sm"
        :class="[{'badge-success': processNestedData(rowData.item, colName) === 'SUCCESS'}, {'badge-warning': processNestedData(rowData.item, colName) === 'PENDING'}, {'badge-danger': processNestedData(rowData.item, colName) === 'FAILED'}, {'badge-info': processNestedData(rowData.item, colName) === 'VOID_PAYMENT'}]"
      >
        {{ humanise(processNestedData(rowData.item, colName)) }}
      </b-badge>
      <template v-else>
        {{ processNestedData(rowData.item, colName) }}
      </template>
    </template>
    <template v-if="colType === 'nestedKeyDatetime'">
      <template v-if="processNestedData(rowData.item, colName) ">
        {{ transformDate(processNestedData(rowData.item, colName), false, 'FF' ) }}
      </template>
      <template v-else>
        -
      </template>
    </template>
    <template v-if="colType === 'nestedKeyMonetary'">
      <vue-numeric
        read-only
        :precision="2"
        :currency="myCurrency"
        separator=","
        :value="processNestedData(rowData.item, colName)"
      />
    </template>
    <template v-if="colType === 'visualcheckbox'">

      <feather-icon
        v-if="rowData.rowSelected "
        size="16"
        class="text-success"
        icon="CheckSquareIcon"
      />
      <feather-icon
        v-if="!rowData.rowSelected "
        size="16"
        icon="SquareIcon"
      />
    </template>
    <template v-if="colType === 'settlementChannel'">
      {{ getSettlementChannelName(rowData.item.settlement_channel ) }}
    </template>
    <template v-if="colType === undefined">
      {{ colData }}
    </template>

  </div>
</template>

<script>
import humaniseMixin from '@/common/humanise.mixin'

const COLLECTIONS_BASE_URL = process.env.VUE_APP_COLLECTIONS_BASE_URL
const INVOICE_BASE_URL = process.env.VUE_APP_INVOICE_BASE_URL

export default {
  components: {},
  mixins: [humaniseMixin],
  props: {
    rowData: {

    },
    colName: {
      type: String,
    },
    colType: {
      type: String,
    },
  },
  computed: {
    collectionBaseUrl() {
      if (process.env.NODE_ENV === 'development') {
        return '/pay/collections/'
      }
      return COLLECTIONS_BASE_URL
    },
    invoiceBaseUrl() {
      if (process.env.NODE_ENV === 'development') {
        return '/pay/invoice/'
      }
      return INVOICE_BASE_URL
    },
    bankList() {
      const bankList = this.$store.getters['systemConstants/getSystemConstantsByKey']('banks')
      if (bankList) {
        return bankList
      }
      return []
    },
    settlementSettings() {
      const settlementSettings = this.$store.getters['systemConstants/getSystemConstantsByKey']('settlement_settings')
      if (settlementSettings) {
        return settlementSettings
      }
      return []
    },
    rowDataObj() {
      return this.rowData
    },
    colData() {
      return this.rowDataObj.item[this.colName]
    },
  },
  methods: {
    async checkBankAccount(rowData) {
      let parsed
      let payload
      if (rowData.data) {
        parsed = JSON.parse(rowData.data)
        console.log('parsed', parsed)

        payload = {
          payout_account_number: rowData.payout_account_number,
          payout_service_id: parsed.payout_service_id,
        }
      }
      await this.toggleModalShowStatus(payload)
    },
    processNestedData(rowDataItem, nestedKey) {
      const splitKey = nestedKey.split('.')
      const key = splitKey[0]
      const skey = splitKey[1]
      return rowDataItem[key][skey]
    },
    parseExtractedJsonData(rowDataItem, nestedKey) {
      const splitKey = nestedKey.split('.')
      const key = splitKey[0]
      const skey = splitKey[1]

      return this.parseJsonData(this.parseJsonData(rowDataItem[key])[skey])
    },
    parseJsonData(data) {
      let parsed
      try {
        parsed = JSON.parse(data)
      } catch (e) {
        return '-'
      }

      return parsed
    },
    getBankName(id) {
      const list = this.$store.getters['systemConstants/getBankList']
      if (Array.isArray(list) && list.length > 0) {
        const selected = list.filter(el => el.id === id)
        console.log(selected)
        if (Array.isArray(selected) && selected.length > 0) {
          return selected[0].name
        } return id
      } return id
    },
    getSettlementChannelName(id) {
      const list = this.settlementSettings
      return list[id - 1].method
    },
    async toggleModalShowStatus(payload = null) {
      const statusStore = await this.$store.getters['systemConstants/getCheckBankAccountModalStatus']
      const newStatus = !statusStore
      await this.$store.dispatch('systemConstants/toggleCheckBankAccountModal', newStatus)
      await this.$store.dispatch('systemConstants/updateCheckBankAccountModal', payload)
    },
  },
}
</script>

<style>

</style>
